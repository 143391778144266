import Solr from './Solr';
import SolrAutocomplete from "./SolrAutocomplete.vue";
const SolrPlugin = {
    install(app) {
        let solr = new Solr();
        app.provide('solr', solr);
        app.component('SolrAutocomplete', SolrAutocomplete);
    }
};
export default SolrPlugin;
