import "../Scss/style.scss";
// @ts-ignore
import AOS from "aos";
import { defineAsyncComponent } from 'vue';
import { Component } from '@vue/runtime-core';
import { createI18n } from 'vue-i18n';

import createApp, { VueDsCookies } from "@digitalwerk/frontend-muster";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Collapse } from 'bootstrap';
import elementClosest from 'element-closest';

elementClosest(window);

const AjaxForm = defineAsyncComponent(() => import('./Components/AjaxForm.vue'));
const DocumentsList = defineAsyncComponent(() => import('./../../../../dw_distributor_documents/Resources/Private/Vue/Documents/List.vue'));
const NewsList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/News/List.vue'));
const ProductsList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Products/List.vue'));
const ProductSlider = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Products/Slider.vue'));
const LocationList = defineAsyncComponent(() => import('./Components/LocationList.vue'));
const ContactList = defineAsyncComponent(() => import('./Components/Contact/List.vue'));
const EventList = defineAsyncComponent(() => import('./Components/Event/List.vue'));
const StatsItem = defineAsyncComponent(() => import('./Components/StatsItem.vue'));
const Timeline = defineAsyncComponent(() => import('./Components/Timeline.vue'));
const SocialShare = defineAsyncComponent(() => import('./Components/SocialShare.vue'));
const SolrSearchResults = defineAsyncComponent(() => import('./Components/SolrSearchResults.vue'));
const ElTabs = defineAsyncComponent(() => import('element-plus/lib/components/tabs'));
import {ElCollapse, ElCollapseItem, ElTabPane} from "element-plus";
import VueSocialSharing from 'vue-social-sharing'
import DownloadListFilterDirective from './Directives/DownloadListFilter';
import PageHeader from './Components/PageHeader.vue';
import TeasersSlider from './Components/TeasersSlider.vue';
import { VueSolr } from "./plugins";

import localeDe from './locales/de.json';
import localeEn from './locales/en.json';

const PREVENT_UNLOAD_CLASSES = [
    '.ajax',
    '.download',
    '#scroll-to-top',
    '[data-photoswipe]',
    '[download]',
    '[href^=\\#]',
    '[href*=ajax]',
    '[href^=javascript]',
    '[href^=mailto]',
    '[href^=tel]',
    '[href*=tx_typoscriptrendering]',
    '[target^=_blank]',
    '[role^=button]',
];
const SCROLL_OFFSET = 140;

const projectRootComponent: Component = {
    components: {
        LocationList,
        ContactList,
        EventList,
        StatsItem,
        Timeline,
        TeasersSlider,
        ElTabs,
        ElTabPane,
        ElCollapse,
        ElCollapseItem,
        'page-header': PageHeader,
        'ajax-form': AjaxForm,
        'documents-list': DocumentsList,
        'news-list': NewsList,
        'products-list': ProductsList,
        'product-slider': ProductSlider,
        SocialShare,
        Swiper,
        SwiperSlide,
        SolrSearchResults
    },
    inject: ['solr', 'cookies'],
    computed: {
        scrollOffset() {
            let offset = -SCROLL_OFFSET;

            if (this.header && this.header.value) {
                offset -= this.header.value.offsetHeight;
            }

            return offset;
        }
    },
    methods: {
        mountedHook() {
            document.querySelectorAll('.collapse').forEach((item) => {
                return new Collapse(item);
            })
            const resizeObserver = new ResizeObserver(entries => {
                AOS.refresh();
            })

            resizeObserver.observe(document.body)
        },
        /* ======= GENERAL METHODS ======= */
        initUnload() {
            let links = 'a';

            PREVENT_UNLOAD_CLASSES.forEach((className) => {
                links += `:not(${className})`;
            });

            document.querySelectorAll<HTMLAnchorElement>(links).forEach((link) => {
                link.addEventListener('click', (event) => {
                    const target = event.currentTarget as HTMLAnchorElement | null;

                    if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
                        return true;
                    } else if (target?.pathname === window.location.pathname) {
                        return true;
                    } else if (target?.getAttribute('id') === 'history-back') {
                        event.preventDefault();
                        if (window.history.length > 1) {
                            window.history.back();
                        }

                        return false;
                    }
                    document.body.classList.remove('loaded');
                    document.body.classList.add('unloading');

                    return true;
                });
            });
        },
    },
    watch: {
        solr: {
            handler: (newValue, oldValue) => {
                document.body.classList.toggle('search-overlay-opened');
            },
            deep: true
        },
        'cookies.accepted.value': {
            handler: function (accepted) {
                if (accepted) {
                    if (window.gtag && typeof window.gtag === 'function') {
                        window.gtag('consent', 'update', {
                            'ad_storage': this.cookies.categories.value['socialmedia'] ? 'granted' : 'denied',
                            'ad_user_data': this.cookies.categories.value['socialmedia'] ? 'granted' : 'denied',
                            'ad_personalization': this.cookies.categories.value['analytical'] ? 'granted' : 'denied',
                            'analytics_storage': this.cookies.categories.value['functional'] ? 'granted' : 'denied',
                            'functionality_storage': this.cookies.categories.value['analytical'] ? 'granted' : 'denied',
                            'personalization_storage': this.cookies.categories.value['analytical'] ? 'granted' : 'denied',
                            'security_storage': this.cookies.categories.value['analytical'] ? 'granted' : 'denied'
                        });

                        window.dataLayer.push({ 'event': 'consentUpdated' });
                    }
                }
            },
            immediate: true
        }
    }
};

// @ts-ignore
const app = createApp(projectRootComponent);

const i18n = createI18n({
    locale: document.querySelector('html')!.lang, // set locale
    fallbackLocale: 'en-US', // set fallback locale
    messages: {
        'en-US': localeEn,
        'de-DE': localeDe,
    },
});

app.directive('download-list-filter', DownloadListFilterDirective);
app.use(i18n);
app.use(VueDsCookies, {
    categories: ['functional', 'analytical', 'socialmedia']
});
app.use(VueSocialSharing);
app.use(VueSolr);

app.mount('#page');


//app.config.compilerOptions.delimiters = ['<%', '%>'];
// @ts-ignore
app.config.devtools = true;
